import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { columnSpacing } from "../../../../theme/Theme";
import Button from "../../../elements/button/Button";
import Text from "../../../elements/Text/Text";
import TextField from "../../../elements/TextField/TextField";

type PropertyBusinessFormThreeProps = {
  onSubmit: (data: unknown) => void;
  addressData: {
    strasse: unknown;
    nr: unknown;
    plz: unknown;
    ort: unknown;
  };
};

const PropertyBusinessFormThree = ({
  onSubmit: onSubmitProp,
  addressData,
}: PropertyBusinessFormThreeProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    onSubmitProp(data);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Text variant="h2" component="h1">
          {addressData.strasse} {addressData.nr}, {addressData.plz}{" "}
          {addressData.ort}
        </Text>
      </Grid>
      <Grid item xs={12} md={5} lg={4} xl={3}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid
            container
            columnSpacing={columnSpacing}
            rowSpacing={{ xs: 2, sm: 3 }}
          >
            <Grid item xs={12}>
              <TextField
                label="Baujahr *"
                {...register("baujahr", { required: true })}
                type="number"
                helperText={errors.baujahr && "Baujahr erforderlich."}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            sx={{
              marginTop: {
                xs: 2,
                sm: 3,
                md: 5,
              },
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
          >
            Weiter
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default PropertyBusinessFormThree;
