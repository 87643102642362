import { Box, Rating, RatingProps } from "@mui/material";
import { useState } from "react";
import Text from "../Text/Text";

export type RateInputProps = Pick<
  RatingProps,
  "name" | "value" | "icon" | "emptyIcon"
> & {
  setValue: (value: number) => void;
  labels: { [index: string]: string };
  helperText?: string;
};

const RateInput = ({
  name,
  value,
  setValue,
  icon,
  emptyIcon,
  labels,
  helperText,
}: RateInputProps) => {
  const [hover, setHover] = useState(-1);

  return (
    <Box>
      <Rating
        name={name}
        value={value}
        precision={1}
        max={3}
        sx={{
          fontSize: 48,
        }}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        icon={icon}
        emptyIcon={emptyIcon}
      />
      <Text variant="body2">{labels[hover !== -1 ? hover : value]}</Text>
      {helperText && (
        <Text
          variant="caption"
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          {helperText}
        </Text>
      )}
    </Box>
  );
};

export default RateInput;
