import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useState } from "react";
import Text from "../Text/Text";

type CheckboxGroupProps = {
  onChange: (data: string[]) => void;
  name: string;
  items: CheckboxGroupItem[];
  error?: string;
};

type CheckboxGroupItem = {
  label: string;
  value: string;
};

const CheckboxGroup = ({
  onChange,
  name,
  error,
  items,
}: CheckboxGroupProps) => {
  const [state, setState] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;

    if (checked) {
      setState((prevState) => {
        const newState = [...prevState, name];
        onChange(newState);

        return newState;
      });
    } else {
      setState((prevState) => {
        const newState = [
          ...prevState.filter((checkboxName) => checkboxName !== name),
        ];

        onChange(newState);

        if (isEmpty(newState)) {
          onChange(null);
        }

        return newState;
      });
    }
  };

  return (
    <FormControl name={name} component="fieldset" variant="standard">
      <FormGroup>
        {items.map(({ label, value }) => (
          <FormControlLabel
            key={label}
            control={
              <Checkbox
                checked={
                  state.findIndex((checkedValue) => checkedValue === value) !==
                  -1
                }
                onChange={handleChange}
                name={value}
              />
            }
            label={label}
            sx={{
              "& .MuiFormControlLabel-label": {
                marginBottom: 0,
              },
            }}
          />
        ))}
      </FormGroup>
      {error && (
        <Text
          variant="caption"
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          {error}
        </Text>
      )}
    </FormControl>
  );
};

export default CheckboxGroup;
