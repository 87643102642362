import Radio, { RadioProps } from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import { FormControlLabel, Grid, GridSize } from "@mui/material";

const SelectItemStyled = styled(Radio)(({ theme }) => ({
  color: theme.palette.secondary.main,
  borderRadius: 0,
  "& :hover": {
    backgraundColor: "transparent !important",
  },
  "&.MuiRadio-root": {
    backgroundColor: "transparent",
  },
}));

type SelectItemProps = RadioProps & {
  label: string;
  selectedValue?: RadioProps["value"];
  gridSize?: { xs?: GridSize; sm?: GridSize; md?: GridSize; lg?: GridSize };
};

const SelectItem = ({
  value,
  id,
  label,
  icon,
  gridSize,
  checked,
  defaultChecked,
  selectedValue,
  ...props
}: SelectItemProps) => (
  <Grid
    key={id}
    container
    item
    xs={6}
    sm={3}
    md={2}
    sx={{
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "-1px",
        left: "-1px",
        width: "calc(100% + 2px)",
        height: "calc(100% + 2px)",
        pointerEvents: "none",
        border: (theme) =>
          selectedValue === value
            ? `2.5px solid ${theme.palette.secondary.main}`
            : `none`,
      },
      border: `1px solid grey`,
    }}
    {...gridSize}
  >
    <FormControlLabel
      value={value}
      labelPlacement="bottom"
      sx={{
        margin: 0,
        width: "100%",
        padding: (theme) => theme.spacing(1, 2.5),
      }}
      disableTypography
      control={
        <SelectItemStyled
          value={value}
          icon={icon}
          checkedIcon={icon}
          id={id}
          disableRipple
          defaultChecked={defaultChecked}
          checked={checked}
          {...props}
        />
      }
      label={label}
    />
  </Grid>
);

export default SelectItem;
