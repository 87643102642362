import { Add, Remove } from "@mui/icons-material";
import { isNil } from "lodash";
import { useState } from "react";
import TextField from "../TextField/TextField";

type Quantity = number | "";

const getQuantityAfterRemove = (prevValue: Quantity, step: number) => {
  if (prevValue !== 0 && prevValue !== "") {
    return prevValue - step;
  }

  return prevValue;
};

const getQuantityAfterAdd = (prevValue: Quantity, step: number) => {
  if (prevValue !== "") {
    return prevValue + step;
  }

  return step;
};

type QuantityInputProps = {
  setValue?: (value: number | "") => void;
  value?: Quantity;
  step?: number;
  helperText?: string;
};

const QuantityInput = ({
  setValue,
  value = "",
  step = 1,
  helperText,
}: QuantityInputProps) => {
  const [quantity, setQuantity] = useState<Quantity>(value);

  const handleRemove = () => {
    setQuantity((prevValue) => {
      const newValue = getQuantityAfterRemove(prevValue, step);
      if (!isNil(setValue)) {
        setValue(newValue);
      }

      return newValue;
    });
  };

  const handleAdd = () => {
    setQuantity((prevValue) => {
      const newValue = getQuantityAfterAdd(prevValue, step);

      if (!isNil(setValue)) {
        setValue(newValue);
      }

      return newValue;
    });
  };

  return (
    <TextField
      sx={{ "& .MuiInputBase-input": { textAlign: "center" } }}
      InputProps={{
        startAdornment: (
          <Remove
            focusable
            sx={{
              cursor: "pointer",
              "&:focus, &:hover": { transform: "scale(1.1)" },
              "&:active": { transform: "scale(1)" },
              outline: "none",
            }}
            tabIndex={0}
            onClick={handleRemove}
          />
        ),
        endAdornment: (
          <Add
            focusable
            sx={{
              cursor: "pointer",
              "&:focus, &:hover": { transform: "scale(1.1)" },
              "&:active": { transform: "scale(1)" },
              outline: "none",
            }}
            tabIndex={0}
            onClick={handleAdd}
          />
        ),
        readOnly: true,
      }}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        tabIndex: -1,
      }}
      value={quantity}
      helperText={helperText}
    />
  );
};

export default QuantityInput;
