import { Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { columnSpacing } from "../../../../theme/Theme";
import Text from "../../../elements/Text/Text";
import { ContactFormForm } from "../../ContactForm/ContactForm";

type PropertyFormFiveProps = {
  onSubmit: (data: unknown) => void;
};

const PropertyFormFive = ({
  onSubmit: onSubmitProp,
}: PropertyFormFiveProps) => {
  const onSubmit = (data) => {
    onSubmitProp(data);
  };

  return (
    <Grid container columnSpacing={columnSpacing}>
      <Grid
        container
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
        alignContent="center"
        justifyContent="center"
        md={6}
        lg={7}
        xl={8}
      >
        <Grid
          sx={{ marginBottom: { xs: 2, sm: 3, md: 4 } }}
          item
          alignSelf="center"
        >
          <StaticImage
            width={1300}
            alt="Terrassenhaus"
            src="../../../../images/City.png"
          />
        </Grid>
        <Grid item alignSelf="center">
          <Text variant="h1">Fast geschafft!</Text>
        </Grid>
      </Grid>
      <Grid item md={6} lg={5} xl={4}>
        <ContactFormForm
          onSubmit={onSubmit}
          submitButtonText="Analyse starten"
        />
      </Grid>
    </Grid>
  );
};

export default PropertyFormFive;
