import { StarOutlineRounded, StarRounded } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { Controller, useForm } from "react-hook-form";
import { columnSpacing } from "../../../../theme/Theme";
import Button from "../../../elements/button/Button";
import RateInput from "../../../elements/RateInput/RateInput";
import SelectGroup from "../../../elements/Select/SelectGroup";
import SelectItem from "../../../elements/Select/SelectItem";
import Text from "../../../elements/Text/Text";

type PropertyPlotFormFourProps = {
  onSubmit: (data: unknown) => void;
};

const plotTypes = [
  {
    value: "bauland",
    icon: (
      <StaticImage
        width={250}
        alt="bauland"
        src="../../../../images/Land.png"
      />
    ),
    id: "bauland",
    label: "Bauland",
  },
  {
    value: "ackerland",
    icon: (
      <StaticImage
        width={250}
        alt="ackerland"
        src="../../../../images/FieldFlat.png"
      />
    ),
    id: "ackerland",
    label: "Ackerland",
  },
  {
    value: "wald/grunland",
    icon: (
      <StaticImage
        width={250}
        alt="wald/grunland"
        src="../../../../images/Field.png"
      />
    ),
    id: "wald/grunland",
    label: "Wald/Grünland",
  },
  {
    value: "sonstige",
    icon: (
      <StaticImage
        width={250}
        alt="sonstige"
        src="../../../../images/Dots.png"
      />
    ),
    id: "sonstige",
    label: "Sonstige",
  },
];

const entwicklungsstandLabels = {
  1: "Nicht entwickelt",
  2: "Teilweise entwickelt",
  3: "Voll entwickelt",
};

const PropertyPlotFormFour = ({
  onSubmit: onSubmitProp,
}: PropertyPlotFormFourProps) => {
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    onSubmitProp(data);
  };

  return (
    <Grid container>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          columnSpacing={columnSpacing}
          rowSpacing={{ xs: 2, sm: 3 }}
        >
          <Grid item xs={12}>
            <Text variant="h2" component="h1" sx={{ marginTop: 2 }}>
              Übersicht
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
              Grundstücktyp
            </Text>
            <Controller
              control={control}
              name="plot-type"
              defaultValue={null}
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <SelectGroup
                  onChange={onChange}
                  value={value}
                  helperText={error && "Bitte Grundstückstyp auswählen."}
                >
                  {plotTypes.map(({ value, id, icon, label }) => (
                    <SelectItem
                      key={id}
                      value={value}
                      id={id}
                      icon={icon}
                      label={label}
                    />
                  ))}
                </SelectGroup>
              )}
            />
          </Grid>
          <Grid item container xs={12} columnSpacing={columnSpacing}>
            <Grid item xs={12} md={5} lg={4} xl={3}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Entwicklungsstand
              </Text>
              <Controller
                control={control}
                name="entwicklungsstand"
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <RateInput
                    value={value}
                    setValue={onChange}
                    icon={<StarRounded fontSize="inherit" />}
                    emptyIcon={<StarOutlineRounded fontSize="inherit" />}
                    labels={entwicklungsstandLabels}
                    helperText={error && "Bitte Entwicklungsstand auswählen."}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          sx={{
            marginTop: {
              xs: 2,
              sm: 3,
              md: 5,
            },
            width: {
              xs: "100%",
              sm: "auto",
            },
          }}
        >
          Weiter
        </Button>
      </form>
    </Grid>
  );
};

export default PropertyPlotFormFour;
