import { ArrowBack } from "@mui/icons-material";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import Text from "../../elements/Text/Text";
import Modal from "../../modules/Modal/Modal";
import Stepper from "../../modules/Stepper/Stepper";
import PropertyFormFive from "./Steps/PropertyFormFive";
import PropertyFormOne from "./Steps/PropertyFormOne";
import PropertyFormTwo from "./Steps/PropertyFormTwo";
import PropertyHouseFormThree from "./House/PropertyHouseFormThree";
import PropertyHouseFormFour from "./House/PropertyHouseFormFour";
import PropertyApartmentFormThree from "./Apartment/PropertyApartmentFormThree";
import PropertyApartmentFormFour from "./Apartment/PropertyApartmentFormFour";
import PropertyPlotFormThree from "./Plot/PropertyPlotFormThree";
import PropertyPlotFormFour from "./Plot/PropertyPlotFormFour";
import PropertyBusinessFormThree from "./Business/PropertyBusinessFormThree";
import PropertyBusinessFormFour from "./Business/PropertyBusinessFormFour";
import Logo from "../../elements/Logo/Logo";
import PageWrapBreakout from "../../modules/PageWrapBreakout/PageWrapBreakout";
import { AnimatePresence, motion } from "framer-motion";

const MotionGrid = motion(Grid);

const PropertyForm = () => {
  const stepperRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [activeForm, setActiveForm] = useState(0);
  const [data, setData] = useState<{ [x: string]: unknown }>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState<
    string | undefined
  >();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleGetBack = useCallback(() => {
    setActiveForm((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const executeScroll = useCallback(() => {
    window.scrollTo(
      0,
      stepperRef?.current?.offsetTop - (isDesktop ? 130 : 110)
    );
  }, [isDesktop]);

  useEffect(() => {
    executeScroll();

    switch (activeForm) {
      case 0:
        setData({});
        setActiveStep(0);
        break;
      case 1:
        setActiveStep(0);
        break;
      case 2:
        setActiveStep(1);
        break;
      case 3:
        setActiveStep(1);
        break;
      case 4:
        setActiveStep(2);
        break;
    }
  }, [activeForm, executeScroll]);

  const onPropertyFormOneSubmit = useCallback((data) => {
    setActiveForm(1);
    setData((prevData) => ({ ...prevData, ...data }));
  }, []);

  const onPropertyFormTwoSubmit = useCallback((data) => {
    setActiveForm(2);
    setData((prevData) => ({ ...prevData, ...data }));
  }, []);

  const onPropertyFormThreeSubmit = useCallback((data) => {
    setActiveForm(3);
    setData((prevData) => ({ ...prevData, ...data }));
  }, []);

  const onPropertyFormFourSubmit = useCallback((data) => {
    setActiveForm(4);
    setData((prevData) => ({ ...prevData, ...data }));
  }, []);

  const submitForm = useCallback(async (data) => {
    const body = JSON.stringify(data);

    setModalErrorMessage(undefined);

    try {
      const response = await fetch("/.netlify/functions/propertyForm", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body,
      });

      const parsedResponse = await response.json();

      if (parsedResponse.statusCode === 500) {
        setModalErrorMessage(parsedResponse.body.message);
      }
    } catch (e) {
      setModalErrorMessage(
        "Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten. Versuche es später!"
      );
    }
  }, []);

  const onPropertyFormFiveSubmit = useCallback(
    async (formFiveData) => {
      setData((prevData) => ({ ...prevData, ...data }));
      await submitForm({ ...data, ...formFiveData });

      setModalOpen(true);
    },
    [data, submitForm]
  );

  return (
    <Grid
      container
      sx={{
        width: "100%",
      }}
      rowSpacing={{ xs: 2, sm: 3, md: 5, lg: 7 }}
    >
      <PageWrapBreakout>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          sx={{
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
            paddingTop: {
              md: 3,
            },
            paddingLeft: {
              xs: 1,
              sm: 8,
            },
            paddingRight: {
              xs: 1,
              sm: 8,
            },
            paddingBottom: {
              xs: 2,
              sm: 4,
            },
          }}
        >
          <Grid
            item
            sm={2}
            md={1}
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
          >
            <Logo
              sx={{
                "& img": {
                  maxWidth: "100%",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={11}>
            <Stepper
              ref={stepperRef}
              activeStep={activeStep}
              steps={["Start", "Übersicht", "Ergebnis"]}
            />
          </Grid>
        </Grid>
      </PageWrapBreakout>

      {activeForm !== 0 && (
        <Grid item container>
          <Grid
            item
            container
            alignItems="center"
            onClick={handleGetBack}
            sx={{
              cursor: "pointer",
              width: "auto",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <ArrowBack sx={{ color: (theme) => theme.palette.primary.main }} />{" "}
            Züruck
          </Grid>
        </Grid>
      )}
      <AnimatePresence exitBeforeEnter>
        {activeForm === 0 && (
          <MotionGrid
            key="form-one"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyFormOne onSubmit={onPropertyFormOneSubmit} />
          </MotionGrid>
        )}
        {activeForm === 1 && (
          <MotionGrid
            key="form-two"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyFormTwo onSubmit={onPropertyFormTwoSubmit} />
          </MotionGrid>
        )}
        {}
        {activeForm === 2 && data.property === "haus" && (
          <MotionGrid
            key="form-three-house"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyHouseFormThree
              onSubmit={onPropertyFormThreeSubmit}
              addressData={{
                strasse: data.strasse,
                nr: data.nr,
                plz: data.plz,
                ort: data.ort,
              }}
            />
          </MotionGrid>
        )}
        {activeForm === 2 && data.property === "wohnung" && (
          <MotionGrid
            key="form-three-apartment"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyApartmentFormThree
              onSubmit={onPropertyFormThreeSubmit}
              addressData={{
                strasse: data.strasse,
                nr: data.nr,
                plz: data.plz,
                ort: data.ort,
              }}
            />
          </MotionGrid>
        )}
        {activeForm === 2 && data.property === "grundstuck" && (
          <MotionGrid
            key="form-three-plot"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyPlotFormThree
              onSubmit={onPropertyFormThreeSubmit}
              addressData={{
                strasse: data.strasse,
                nr: data.nr,
                plz: data.plz,
                ort: data.ort,
              }}
            />
          </MotionGrid>
        )}
        {activeForm === 2 && data.property === "gewerbe" && (
          <MotionGrid
            key="form-three-business"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyBusinessFormThree
              onSubmit={onPropertyFormThreeSubmit}
              addressData={{
                strasse: data.strasse,
                nr: data.nr,
                plz: data.plz,
                ort: data.ort,
              }}
            />
          </MotionGrid>
        )}
        {activeForm === 3 && data.property === "haus" && (
          <MotionGrid
            key="form-four-house"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyHouseFormFour onSubmit={onPropertyFormFourSubmit} />
          </MotionGrid>
        )}
        {activeForm === 3 && data.property === "wohnung" && (
          <MotionGrid
            key="form-four-apartment"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyApartmentFormFour onSubmit={onPropertyFormFourSubmit} />
          </MotionGrid>
        )}
        {activeForm === 3 && data.property === "grundstuck" && (
          <MotionGrid
            key="form-four-plot"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyPlotFormFour onSubmit={onPropertyFormFourSubmit} />
          </MotionGrid>
        )}
        {activeForm === 3 && data.property === "gewerbe" && (
          <MotionGrid
            key="form-four-business"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyBusinessFormFour onSubmit={onPropertyFormFourSubmit} />
          </MotionGrid>
        )}
        {activeForm === 4 && (
          <MotionGrid
            key="form-five"
            item
            xs={12}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PropertyFormFive onSubmit={onPropertyFormFiveSubmit} />
          </MotionGrid>
        )}
      </AnimatePresence>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        {modalErrorMessage ? (
          <Text
            variant="body1"
            sx={{ color: (theme) => theme.palette.error.main }}
          >
            {modalErrorMessage}
          </Text>
        ) : (
          <>
            <Text variant="body1">Liebe:r Immobilienbesitzer:in</Text>
            <Text variant="body1">
              Vielen Dank für Ihr Interesse an unserem kostenlosen Angebot Ihre
              Immobilie zu bewerten. Unser Team überprüft die eingetragenen
              Angaben und wir sich schnellstmöglich bei Ihnen melden.
            </Text>
            <Text variant="body1">
              Für Ihre Geduld danken wir Ihnen vielmals.
            </Text>
            <Text variant="body1">Ihr cubical Team</Text>
          </>
        )}
      </Modal>
    </Grid>
  );
};

export default PropertyForm;
