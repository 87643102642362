import { Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../elements/button/Button";
import SelectGroup from "../../../elements/Select/SelectGroup";
import SelectItem from "../../../elements/Select/SelectItem";
import Text from "../../../elements/Text/Text";

type PropertyFormOneProps = {
  onSubmit: (data: unknown) => void;
};

const properties = [
  {
    value: "haus",
    icon: (
      <StaticImage
        width={250}
        alt="haus"
        src="../../../../images/HouseOutlined.png"
      />
    ),
    id: "haus",
    label: "Haus",
  },
  {
    value: "grundstuck",
    icon: (
      <StaticImage
        width={250}
        alt="grundstück"
        src="../../../../images/Land.png"
      />
    ),
    id: "grundstuck",
    label: "Grundstück",
  },
  {
    value: "wohnung",
    icon: (
      <StaticImage
        width={250}
        alt="wohnung"
        src="../../../../images/Buildings.png"
      />
    ),
    id: "wohnung",
    label: "Wohnung",
  },
  {
    value: "gewerbe",
    icon: (
      <StaticImage
        width={250}
        alt="gewerbe"
        src="../../../../images/Building.png"
      />
    ),
    id: "gewerbe",
    label: "Gewerbe",
  },
];

const PropertyFormOne = ({ onSubmit: onSubmitProp }: PropertyFormOneProps) => {
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    onSubmitProp(data);
  };

  return (
    <Grid container rowSpacing={{ xs: 2, sm: 3 }}>
      <Grid item xs={12}>
        <Text variant="h2">Bewertet wird ein(e)…</Text>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            control={control}
            name="property"
            defaultValue={null}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectGroup
                onChange={onChange}
                value={value}
                helperText={error && "Bitte Objekt auswählen."}
              >
                {properties.map(({ value, id, icon, label }) => (
                  <SelectItem
                    key={id}
                    value={value}
                    id={id}
                    icon={icon}
                    label={label}
                    gridSize={{ md: 3 }}
                  />
                ))}
              </SelectGroup>
            )}
          />
          <Button
            type="submit"
            sx={{
              display: "block",
              width: {
                xs: "100%",
                sm: "auto",
              },
              marginLeft: "auto",
              marginTop: {
                xs: 2,
                sm: 3,
                md: 6,
                lg: 10,
              },
            }}
          >
            Weiter
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default PropertyFormOne;
