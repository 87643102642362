import { Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDebounce } from "rooks";
import { columnSpacing } from "../../../../theme/Theme";
import Button from "../../../elements/button/Button";
import Text from "../../../elements/Text/Text";
import TextField from "../../../elements/TextField/TextField";
import Map from "../../InfoCard/Map";

type PropertyFormTwoProps = {
  onSubmit: (data: unknown) => void;
};

const PropertyFormTwo = ({ onSubmit: onSubmitProp }: PropertyFormTwoProps) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const [geocodingStrasse, setGeocodingStrasse] = useState("");
  const [geocodingNr, setGeocodingNr] = useState("");
  const [geocodingPlz, setGeocodingPlz] = useState("");
  const [geocodingOrt, setGeocodingOrt] = useState("");

  const [strasse, nr, plz, ort] = watch(["strasse", "nr", "plz", "ort"]);

  const setDebouncedStrasse = useDebounce(setGeocodingStrasse, 500);
  const setDebouncedNr = useDebounce(setGeocodingNr, 500);
  const setDebouncedPlz = useDebounce(setGeocodingPlz, 500);
  const setDebouncedOrt = useDebounce(setGeocodingOrt, 500);

  useEffect(() => {
    if (isValid) {
      setDebouncedStrasse(strasse);
    }
  }, [isValid, setDebouncedStrasse, strasse]);

  useEffect(() => {
    if (isValid) {
      setDebouncedNr(nr);
    }
  }, [isValid, setDebouncedNr, nr]);

  useEffect(() => {
    if (isValid) {
      setDebouncedPlz(plz);
    }
  }, [isValid, setDebouncedPlz, plz]);

  useEffect(() => {
    if (isValid) {
      setDebouncedOrt(ort);
    }
  }, [isValid, setDebouncedOrt, ort]);

  const onSubmit = (data) => {
    onSubmitProp(data);
  };

  return (
    <Grid
      container
      rowSpacing={{ xs: 2, md: 0 }}
      columnSpacing={{ xs: 0, md: 4 }}
      direction={{ xs: "column", sm: "row" }}
    >
      <Grid item container xs={12} md={5}>
        <Grid item xs={12}>
          <Text variant="h2">Welches Eigenheim soll bewertet werden?</Text>
          <Text variant="body1">
            Anhand der Adresse werden öffentliche Informationen aus dem
            Gebäuderegister berücksichtigt
          </Text>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container columnSpacing={columnSpacing}>
              <Grid item xs={9}>
                <TextField
                  label="Strasse *"
                  {...register("strasse", { required: true })}
                  helperText={errors.strasse && "Strasse ist erforderlich."}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Nr. *"
                  type="number"
                  {...register("nr", { required: true })}
                  helperText={errors.nr && "Nr. ist erforderlich."}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={columnSpacing}>
              <Grid item xs={3}>
                <TextField
                  label="PLZ *"
                  type="number"
                  {...register("plz", { required: true })}
                  helperText={errors.plz && "PLZ ist erforderlich."}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label="Ort *"
                  {...register("ort", { required: true })}
                  helperText={errors.ort && "Ort ist erforderlich."}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              sx={{
                display: "block",
                marginTop: {
                  xs: 2,
                  sm: 3,
                  md: 5,
                },
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              Weiter
            </Button>
          </form>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          position: "relative",
          minHeight: {
            xs: "100vw",
            md: "unset",
          },
        }}
      >
        {!isEmpty(geocodingStrasse) &&
          !isEmpty(geocodingNr) &&
          !isEmpty(geocodingPlz) &&
          !isEmpty(geocodingOrt) && (
            <Map
              location={{
                address: `${geocodingStrasse} ${geocodingNr} ${geocodingPlz} ${geocodingOrt}`,
              }}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default PropertyFormTwo;
