import { PaidOutlined, PaidRounded } from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { Controller, useForm } from "react-hook-form";
import { columnSpacing } from "../../../../theme/Theme";
import Button from "../../../elements/button/Button";
import QuantityInput from "../../../elements/QuantityInput/QuantityInput";
import RateInput from "../../../elements/RateInput/RateInput";
import SelectGroup from "../../../elements/Select/SelectGroup";
import SelectItem from "../../../elements/Select/SelectItem";
import Text from "../../../elements/Text/Text";
import TextField from "../../../elements/TextField/TextField";

type PropertyBusinessFormFourProps = {
  onSubmit: (data: unknown) => void;
};

const businessTypes = [
  {
    value: "buro/praxis",
    icon: (
      <StaticImage
        width={250}
        alt="freistehend"
        src="../../../../images/Desk.png"
      />
    ),
    id: "buro/praxis",
    label: "Büro/Praxis",
  },
  {
    value: "wohn/geschaft",
    icon: (
      <StaticImage
        width={250}
        alt="Wohn/Geschäft"
        src="../../../../images/OldBuilding.png"
      />
    ),
    id: "wohn/geschaft",
    label: "Wohn/Geschäft",
  },
  {
    value: "industrie",
    icon: (
      <StaticImage
        width={250}
        alt="Industrie"
        src="../../../../images/Factory.png"
      />
    ),
    id: "industrie",
    label: "Industrie",
  },
  {
    value: "retail",
    icon: (
      <StaticImage
        width={250}
        alt="Retail"
        src="../../../../images/Store.png"
      />
    ),
    id: "retail",
    label: "Retail",
  },
  {
    value: "sonstige",
    icon: (
      <StaticImage
        width={250}
        alt="Sonstige"
        src="../../../../images/Dots.png"
      />
    ),
    id: "sonstige",
    label: "Sonstige",
  },
];

const qualitatsstandardLabels = {
  1: "Einfach",
  2: "Durchschnittlich",
  3: "Gehoben",
};

const PropertyBusinessFormFour = ({
  onSubmit: onSubmitProp,
}: PropertyBusinessFormFourProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    onSubmitProp(data);
  };

  return (
    <Grid container>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          columnSpacing={columnSpacing}
          rowSpacing={{ xs: 2, sm: 3 }}
        >
          <Grid item xs={12}>
            <Text variant="h2" component="h1" sx={{ marginTop: 2 }}>
              Übersicht
            </Text>
            <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
              Gebäudeart
            </Text>
            <Controller
              control={control}
              name="gebaudeart"
              defaultValue={null}
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <SelectGroup
                  onChange={onChange}
                  value={value}
                  helperText={error && "Bitte Gebäudeart auswählen."}
                >
                  {businessTypes.map(({ value, id, icon, label }) => (
                    <SelectItem
                      key={id}
                      value={value}
                      id={id}
                      icon={icon}
                      label={label}
                    />
                  ))}
                </SelectGroup>
              )}
            />
          </Grid>
          <Grid item container xs={12} columnSpacing={columnSpacing}>
            <Grid item xs={12} md={5} lg={4} xl={3}>
              <TextField
                label="Grundstücksfläche *"
                type="number"
                {...register("grundstucksflache", { required: true })}
                helperText={
                  errors.grundstucksflache &&
                  "Grundstücksfläche ist erforderlich."
                }
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ "& .MuiTypography-root": { marginBottom: 0 } }}
                    >
                      <Text variant="body1">
                        m<sup>2</sup>
                      </Text>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} columnSpacing={columnSpacing}>
            <Grid item xs={12} md={5} lg={4} xl={3}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Anzahl Etagen
              </Text>
              <Controller
                control={control}
                name="anzahl-etagen"
                defaultValue={null}
                rules={{
                  required: true,
                  min: 1,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <QuantityInput
                    step={1}
                    setValue={onChange}
                    value={value}
                    helperText={
                      error && "Bitte wählen Sie die Anzahl Etagen aus"
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} columnSpacing={columnSpacing}>
            <Grid item xs={12} md={5} lg={4} xl={3}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Anzahl Zimmer
              </Text>
              <Controller
                control={control}
                name="anzahl-zimmer"
                defaultValue={null}
                rules={{
                  required: true,
                  min: 0.5,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <QuantityInput
                    step={0.5}
                    setValue={onChange}
                    value={value}
                    helperText={
                      error && "Bitte wählen Sie die Anzahl Zimmer aus."
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} columnSpacing={columnSpacing}>
            <Grid item xs={12} md={5} lg={4} xl={3}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Qualitätsstandard
              </Text>
              <Controller
                control={control}
                name="qualitatsstandard"
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <RateInput
                    value={value}
                    setValue={onChange}
                    icon={<PaidRounded fontSize="inherit" />}
                    emptyIcon={<PaidOutlined fontSize="inherit" />}
                    labels={qualitatsstandardLabels}
                    helperText={error && "Bitte Qualitätsstandard auswählen."}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          sx={{
            marginTop: {
              xs: 2,
              sm: 3,
              md: 5,
            },
            width: {
              xs: "100%",
              sm: "auto",
            },
          }}
        >
          Weiter
        </Button>
      </form>
    </Grid>
  );
};

export default PropertyBusinessFormFour;
