import { graphql, PageProps } from "gatsby";
import { isNil } from "lodash";
import PageTransitionWrapper from "../components/behaviors/PageTransitionWrapper/PageTransitionWrapper";
import CMSHeroA from "../components/cms/HeroA/CMSHeroA";
import PageSpacerWrapper from "../components/elements/PageSpacerWrapper/PageSpacerWrapper";
import PageWrapBreakout from "../components/modules/PageWrapBreakout/PageWrapBreakout";
import Seo from "../components/seo";
import PropertyForm from "../components/views/PropertyForm/PropertyForm";

const PropertyFormPage = ({
  data,
}: PageProps<GatsbyTypes.PropertyFormPageQuery>) => {
  const pageData = data?.prismicPropertyFormPage?.data;

  return (
    <PageTransitionWrapper pageId="property-form-page">
      <Seo
        title={pageData?.seo_title}
        description={pageData?.seo_description}
      />
      <PageSpacerWrapper>
        {!isNil(pageData?.hero) && (
          <PageWrapBreakout>
            <CMSHeroA data={pageData.hero} />
          </PageWrapBreakout>
        )}
        <PropertyForm />
      </PageSpacerWrapper>
    </PageTransitionWrapper>
  );
};

export default PropertyFormPage;

export const query = graphql`
  query PropertyFormPage {
    prismicPropertyFormPage {
      data {
        hero {
          button_label
          button_link {
            document {
              ... on PrismicLandingPage {
                url
              }
              ... on PrismicCompanyPage {
                url
              }
              ... on PrismicProjectsPage {
                id
                url
              }
              ... on PrismicServicesPage {
                url
              }
              ... on PrismicWeArePage {
                url
              }
              ... on PrismicContactPage {
                url
              }
              ... on PrismicPropertyFormPage {
                url
              }
            }
            url
            localFile {
              publicURL
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          mobile_image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        seo_description
        seo_title
      }
    }
  }
`;
