import {
  useCallback,
  useMemo,
  useState,
  Children,
  cloneElement,
  isValidElement,
  forwardRef,
  Ref,
} from "react";

import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import Text from "../Text/Text";

type SelectGroupProps = RadioGroupProps & {
  helperText?: string;
};

const SelectGroup = (
  { children: childrenProp, onChange, helperText, ...props }: SelectGroupProps,
  ref?: Ref<HTMLDivElement>
) => {
  const [selectedValue, setValue] = useState();

  const handleChange = useCallback(
    (e) => {
      onChange(e, e.target.value);
      setValue(e.target.value);
    },
    [onChange]
  );

  const children = useMemo(
    () =>
      Children.map(childrenProp, (child) => {
        if (!isValidElement(child)) {
          return null;
        }
        return cloneElement(child, {
          ...child.props,
          selectedValue: selectedValue,
        });
      }),
    [childrenProp, selectedValue]
  );

  return (
    <>
      <RadioGroup
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          minWidth: "100%",
        }}
        onChange={handleChange}
        ref={ref}
        {...props}
      >
        {children}
      </RadioGroup>
      {helperText && (
        <Text
          variant="caption"
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          {helperText}
        </Text>
      )}
    </>
  );
};

export default forwardRef(SelectGroup);
