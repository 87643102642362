import { Grid, InputAdornment } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { Controller, useForm } from "react-hook-form";
import { columnSpacing } from "../../../../theme/Theme";
import Button from "../../../elements/button/Button";
import QuantityInput from "../../../elements/QuantityInput/QuantityInput";
import SelectGroup from "../../../elements/Select/SelectGroup";
import SelectItem from "../../../elements/Select/SelectItem";
import Text from "../../../elements/Text/Text";
import TextField from "../../../elements/TextField/TextField";

type PropertyHouseFormThreeProps = {
  onSubmit: (data: unknown) => void;
  addressData: {
    strasse: unknown;
    nr: unknown;
    plz: unknown;
    ort: unknown;
  };
};

const dachtyps = [
  {
    value: "steildach",
    icon: (
      <StaticImage
        width={250}
        alt="steildach"
        src="../../../../images/House.png"
      />
    ),
    id: "steildach",
    label: "Steildach",
  },
  {
    value: "flachdach",
    icon: (
      <StaticImage
        width={250}
        alt="flachdach"
        src="../../../../images/FlatRoof.png"
      />
    ),
    id: "flachdach",
    label: "Flachdach",
  },
  {
    value: "pultdach",
    icon: (
      <StaticImage
        width={250}
        alt="pultdach"
        src="../../../../images/SideRoof.png"
      />
    ),
    id: "pultdach",
    label: "Pultdach",
  },
];

const PropertyHouseFormThree = ({
  onSubmit: onSubmitProp,
  addressData,
}: PropertyHouseFormThreeProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    onSubmitProp(data);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Text variant="h2" component="h1">
          {addressData.strasse} {addressData.nr}, {addressData.plz}{" "}
          {addressData.ort}
        </Text>
      </Grid>
      <Grid item>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid
            container
            columnSpacing={columnSpacing}
            rowSpacing={{ xs: 2, sm: 3 }}
          >
            <Grid item container xs={12} columnSpacing={columnSpacing}>
              <Grid item xs={12} md={5} lg={4} xl={3}>
                <TextField
                  label="Baujahr *"
                  {...register("baujahr", { required: true })}
                  type="number"
                  helperText={errors.baujahr && "Baujahr erforderlich."}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} columnSpacing={columnSpacing}>
              <Grid item xs={12} md={5} lg={4} xl={3}>
                <TextField
                  label="Grundstücksfläche *"
                  type="number"
                  {...register("grundstucksflache", { required: true })}
                  helperText={
                    errors.grundstucksflache &&
                    "Grundstücksfläche ist erforderlich."
                  }
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ "& .MuiTypography-root": { marginBottom: 0 } }}
                      >
                        <Text variant="body1">
                          m<sup>2</sup>
                        </Text>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Dachtyp
              </Text>
              <Controller
                control={control}
                name="dachtyp"
                defaultValue={null}
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <SelectGroup
                    onChange={onChange}
                    value={value}
                    helperText={error && "Bitte Dachtyp auswählen."}
                  >
                    {dachtyps.map(({ value, id, icon, label }) => (
                      <SelectItem
                        key={id}
                        value={value}
                        id={id}
                        icon={icon}
                        label={label}
                      />
                    ))}
                  </SelectGroup>
                )}
              />
            </Grid>
            <Grid item container xs={12} columnSpacing={columnSpacing}>
              <Grid item xs={12} md={5} lg={4} xl={3}>
                <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                  Stockwerke
                </Text>
                <Controller
                  control={control}
                  name="stockwerke"
                  defaultValue={null}
                  rules={{
                    required: true,
                    min: 1,
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <QuantityInput
                      setValue={onChange}
                      value={value}
                      helperText={error && "Bitte Stockwerk auswählen."}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            sx={{
              marginTop: {
                xs: 2,
                sm: 3,
                md: 5,
              },
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
          >
            Weiter
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default PropertyHouseFormThree;
