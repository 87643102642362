import {
  Step,
  StepLabel,
  StepConnector,
  Stepper as MuiStepper,
  StepperProps,
} from "@mui/material";

import {
  CheckCircle,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";

import { StepIconProps } from "@mui/material/StepIcon";
import { styled } from "@mui/material/styles";
import { forwardRef, Ref } from "react";

type FormStepperProps = StepperProps & {
  steps: string[];
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor: theme.palette.secondary.main,
  },
}));

const FormStepIcon = ({ active, completed }: StepIconProps) => {
  if (completed) {
    return <CheckCircle />;
  }
  if (active) {
    return <RadioButtonChecked />;
  }

  return <RadioButtonUnchecked />;
};

const Stepper = (
  { activeStep, steps }: FormStepperProps,
  ref?: Ref<HTMLDivElement>
) => {
  return (
    <MuiStepper
      connector={<QontoConnector />}
      activeStep={activeStep}
      alternativeLabel
      sx={{ width: "100%" }}
      ref={ref}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            sx={{
              "& .MuiStepLabel-label": {
                color: (theme) => theme.palette.secondary.main,
              },
            }}
            StepIconComponent={FormStepIcon}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default forwardRef(Stepper);
