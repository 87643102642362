import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  Radio,
  RadioGroup as MuiRadioGroup,
} from "@mui/material";
import Text from "../Text/Text";

type RadioGroupProps = {
  onChange: FormControlProps["onChange"];
  name: string;
  items: RadioGroupItem[];
  error?: string;
};

type RadioGroupItem = {
  label: string;
  value: string;
};

const RadioGroup = ({ onChange, name, error, items }: RadioGroupProps) => {
  return (
    <FormControl onChange={onChange}>
      <MuiRadioGroup name={name}>
        {items.map(({ label, value }) => (
          <FormControlLabel
            key={label}
            value={value}
            control={<Radio />}
            label={label}
            sx={{
              "& .MuiFormControlLabel-label": {
                marginBottom: 0,
              },
            }}
          />
        ))}
      </MuiRadioGroup>
      {error && (
        <Text
          variant="caption"
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          {error}
        </Text>
      )}
    </FormControl>
  );
};

export default RadioGroup;
