import React, { useMemo, useRef } from "react";
import { Box } from "@mui/material";
import { isNil } from "lodash";

export type MapProps = {
  location:
    | { latitude: number; longitude: number; address?: never }
    | { latitude?: never; longitude?: never; address: string };
  zoom?: number;
};
const Map = ({ location: { longitude, latitude, address } }: MapProps) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const mapLocation = useMemo(() => {
    if (!isNil(address)) {
      return address;
    }

    return `${latitude},${longitude}`;
  }, [address, latitude, longitude]);

  return (
    <Box
      ref={mapContainerRef}
      sx={{
        width: "100%",
        height: "100%",
        zIndex: 100,
        position: "absolute",
      }}
    >
      <iframe
        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GOOGLE_PLATFORM_ID}&q=${mapLocation}`}
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen={false}
        aria-hidden="false"
        tabIndex={0}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
};

export default Map;
