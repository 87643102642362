import {
  PaidOutlined,
  PaidRounded,
  StarOutlineRounded,
  StarRounded,
} from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { columnSpacing } from "../../../../theme/Theme";
import Button from "../../../elements/button/Button";
import CheckboxGroup from "../../../elements/CheckboxGroup/CheckboxGroup";
import QuantityInput from "../../../elements/QuantityInput/QuantityInput";
import RadioGroup from "../../../elements/RadioGroup/RadioGroup";
import RateInput from "../../../elements/RateInput/RateInput";
import Text from "../../../elements/Text/Text";
import TextField from "../../../elements/TextField/TextField";

type PropertyApartmentFormFourProps = {
  onSubmit: (data: unknown) => void;
};

const zustandLabels = {
  1: "Sanierungsbedürftig",
  2: "Intakt",
  3: "Neuwertig",
};

const ausbaustandardLabels = {
  1: "Einfach",
  2: "Durchschnittlich",
  3: "Gehoben",
};

const bauqualitatLabels = {
  1: "Einfach",
  2: "Durchschnittlich",
  3: "Gehoben",
};

const mikrolageLabels = {
  1: "Mit leichten Defiziten",
  2: "Durchschnittlich",
  3: "Sehr gut",
};

const minergiestandardItems = [
  {
    label: "Ja",
    value: "ja",
  },
  {
    label: "Nein",
    value: "nein",
  },
  {
    label: "Nicht ganz klar",
    value: "nicht ganz klar",
  },
];

const wohnungstypItems = [
  {
    label: "Wohnung",
    value: "wohnung",
  },
  {
    label: "Maisonette",
    value: "maisonette",
  },
  {
    label: "Attikawohnung",
    value: "attikawohnung",
  },
  {
    label: "Dachgeschoss",
    value: "dachgeschoss",
  },
  {
    label: "Etagenwohnung",
    value: "etagenwohnung",
  },
  {
    label: "Terrassenwohnung",
    value: "terrassenwohnung",
  },
  {
    label: "Sonstige",
    value: "sonstige",
  },
];

const PropertyApartmentFormFour = ({
  onSubmit: onSubmitProp,
}: PropertyApartmentFormFourProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    onSubmitProp(data);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={5} lg={4} xl={3}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid
            container
            columnSpacing={columnSpacing}
            rowSpacing={{ xs: 2, sm: 3 }}
          >
            <Grid item xs={12}>
              <Text variant="h2" component="h1" sx={{ marginTop: 2 }}>
                Übersicht
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Wohnungstyp
              </Text>
              <Controller
                control={control}
                name="wohnungstyp"
                defaultValue={null}
                rules={{ required: true }}
                render={({
                  field: { onChange, name },
                  fieldState: { error },
                }) => (
                  <CheckboxGroup
                    name={name}
                    onChange={onChange}
                    items={wohnungstypItems}
                    error={error && "Wohnungstyp ist erforderlich."}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nettowohnfläche *"
                type="number"
                {...register("nettowohnflache", { required: true })}
                helperText={
                  errors.nettowohnflache && "Nettowohnfläche ist erforderlich."
                }
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ "& .MuiTypography-root": { marginBottom: 0 } }}
                    >
                      <Text variant="body1">
                        m<sup>2</sup>
                      </Text>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Zustand der Immobilie
              </Text>
              <Controller
                control={control}
                name="zustand"
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <RateInput
                    value={value}
                    setValue={onChange}
                    icon={<StarRounded fontSize="inherit" />}
                    emptyIcon={<StarOutlineRounded fontSize="inherit" />}
                    labels={zustandLabels}
                    helperText={
                      error && "Bitte Zustand der Immobilie auswählen."
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Ausbaustandard
              </Text>
              <Controller
                control={control}
                name="ausbaustandard"
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <RateInput
                    value={value}
                    setValue={onChange}
                    icon={<PaidRounded fontSize="inherit" />}
                    emptyIcon={<PaidOutlined fontSize="inherit" />}
                    labels={ausbaustandardLabels}
                    helperText={error && "Bitte Ausbaustandard auswählen."}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Bauqualität
              </Text>
              <Controller
                control={control}
                name="bauqualitat"
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <RateInput
                    value={value}
                    setValue={onChange}
                    icon={<StarRounded fontSize="inherit" />}
                    emptyIcon={<StarOutlineRounded fontSize="inherit" />}
                    labels={bauqualitatLabels}
                    helperText={error && "Bitte Bauqualität auswählen."}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Anzahl Badezimmer
              </Text>
              <Controller
                control={control}
                name="anzahl-badezimmer"
                defaultValue={null}
                rules={{
                  required: true,
                  min: 1,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <QuantityInput
                    step={1}
                    setValue={onChange}
                    value={value}
                    helperText={
                      error && "Bitte wählen Sie die Anzahl Badezimmer aus."
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Mikrolage
              </Text>
              <Controller
                control={control}
                name="mikrolage"
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <RateInput
                    value={value}
                    setValue={onChange}
                    icon={<StarRounded fontSize="inherit" />}
                    emptyIcon={<StarOutlineRounded fontSize="inherit" />}
                    labels={mikrolageLabels}
                    helperText={error && "Bitte Mikrolage auswählen."}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Minergiestandard
              </Text>
              <Controller
                control={control}
                name="minergiestandard"
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({
                  field: { onChange, name },
                  fieldState: { error },
                }) => (
                  <RadioGroup
                    name={name}
                    onChange={onChange}
                    error={error && "Minergiestandard ist erforderlich."}
                    items={minergiestandardItems}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Text variant="h4" component="h2" sx={{ marginTop: 2 }}>
                Anzahl Garagen
              </Text>
              <Controller
                control={control}
                name="anzahl-garagen"
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <QuantityInput
                    step={1}
                    setValue={onChange}
                    value={value}
                    helperText={
                      error && "Bitte wählen Sie die Anzahl Garagen aus."
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            sx={{
              marginTop: {
                xs: 2,
                sm: 3,
                md: 5,
              },
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
          >
            Weiter
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default PropertyApartmentFormFour;
